<template>
  <div class="">
    <div class="container w-full xl:w-6/12 mx-auto py-10 px-4 md:px-0">
      <ViewTitle :title="$t('about.title')" />
      <ViewSubtitle :subtitle="$t('about.subtitle')" />
      <div class="md:flex">
        <div>
          <h3
            class="
              font-bold
              uppercase
              text-xl text-gray-700
              dark:text-white
              mb-3
            "
          >
            {{ $t("about.content.subtitle-1") }}
          </h3>
          <p>
            {{ $t("about.content.paragraph-1-1") }}
          </p>
          <p>
            {{ $t("about.content.paragraph-1-2") }}
          </p>
          <p class="mb-10">
            {{ $t("about.content.paragraph-1-3") }}
          </p>

          <DownloadExt />

          <!--
          <h3 class="font-bold uppercase text-xl text-gray-700 mb-3 mt-10">
            {{ $t("about.content.subtitle-2") }}
          </h3>
          <p>
            {{ $t("about.content.paragraph-2-1") }}
          </p>

          <p class="mt-5">
            {{ $t("about.content.list-title") }}
          </p>

          <ul class="list-disc list-inside">
            <li>
              {{ $t("about.content.list-item-1") }}
            </li>
            <li>
              {{ $t("about.content.list-item-2") }}
            </li>
            <li>
              {{ $t("about.content.list-item-3") }}
            </li>
            <li>
              {{ $t("about.content.list-item-4") }}
            </li>
            <li>
              {{ $t("about.content.list-item-5") }}
            </li>
            <li>
              {{ $t("about.content.list-item-6") }}
            </li>
          </ul> -->
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import DownloadExt from "@/components/DownloadExt";
import ViewTitle from "@/components/ViewTitle.vue";
import ViewSubtitle from "@/components/ViewSubtitle.vue";

export default {
  components: {
    DownloadExt,
    ViewTitle,
    ViewSubtitle,
  },
};
</script>
